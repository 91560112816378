
<template>
  <div>
    <CToaster position='top-right' :autohide='1500'>
            <template v-for='(toast, index) in toasts'>
                <CToast :key="'toast' + toast.text + index" :show='true'
                    :header="toast.header === '' ? null : toast.header">
                    {{ toast.text }}
                </CToast>
            </template>
        </CToaster>
    <CRow>
      <CCol class="col-12">
        <CCard>
          <CCardHeader>
            <strong>Yeni Bordro Ekle</strong>
          </CCardHeader>
          <CCardBody>
            <CRow>
              <CCol class="col-lg-4 col-md-4 col-12">

                <label>Öğretmen Seç</label>
                <Select2 v-model="selectedTeacher" :options="TeacherOptions" name="Öğretmen Seç"
                  placeholder="Öğretmen Seç" @change="myChangeEvent($event)" @select="mySelectEvent($event)" />
              </CCol>
              <CCol class='col-lg-3 col-md-3 col-12'>
                <CInput placeholder='Başlangıç ​​tarihi' type='date' label='Başlangıç ​​tarihi' v-model="startDate"
                  :is-valid="dateValidator">
                </CInput>
              </CCol>
              <CCol class='col-lg-3 col-md-3 col-12'>
                <CInput placeholder='bitiş tarihi' type='date' label='bitiş tarihi' v-model="endDate"
                  :is-valid="dateValidator">
                </CInput>
              </CCol>
              <CCol class='col-lg-2 col-md-2 col-4'>
                <CButton style="margin-top: 28px;" @click='filterRecord' block color='dark'>filtre</CButton>
              </CCol>
            </CRow>

            <CDataTable :items="tableData" :fields="fields" column-filter clickable-rows hover @row-clicked="rowClicked">
              <template #select-filter>
                <CInputCheckbox style="padding-left: 2rem" @update:checked="(e) => checkAll(e)" custom />
              </template>
              <template #select="{ item, index }">
                <td>
                  <CInputCheckbox :checked="item._selected" @update:checked="() => check(item, index)" custom />
                </td>
              </template>
            </CDataTable>

            <CRow>
              <CCol class='col-8'></CCol>
              <CCol class='col-4'>
                <CInput disabled placeholder='Saatlik ücret' type='text' description='ex. Saatlik ücret' label='Saatlik ücret'
                  v-model="hourlyRate">
                  <template #prepend-content>
                    <CIcon name='cil-font' />
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class='col-8'></CCol>
              <CCol class='col-4'>
                <CInput disabled placeholder='Toplam Dakika' type='text' description='ex. Toplam Dakika' label='Toplam Dakika'
                  v-model="totalMinuts">
                  <template #prepend-content>
                    <CIcon name='cil-font' />
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class='col-8'></CCol>
              <CCol class='col-4'>
                <CInput disabled placeholder='Toplam Saat' type='text' description='ex. Toplam Saat' label='Toplam Saat'
                  v-model="totalHours">
                  <template #prepend-content>
                    <CIcon name='cil-font' />
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class='col-8'></CCol>
              <CCol class='col-4'>
                <CInput disabled placeholder='Toplam tutar' type='text' description='ex. Toplam tutar' label='Toplam tutar'
                  v-model="totalAmount">
                  <template #prepend-content>
                    <CIcon name='cil-font' />
                  </template>
                </CInput>
              </CCol>
            </CRow>
            <CRow>
              <CCol class='col-8'></CCol>
              <CCol class='col-4'>
                <CSelect label="Status" v-model="status" vertical :options="options"
                  placeholder="Tamamlandı Olarak İşaretle" />
              </CCol>
            </CRow>
            <CRow>
              <CCol class='col-8'></CCol>
              <CCol class='col-4'>
                <CButton style="margin-top: 28px;" @click='submitPayroll' block color='dark' :disabled="isDisabled">Submit</CButton>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

  </div>
</template>
<script>

import { TeacherAdministration, AppointmentAdministration } from '@/services/api-service'
import VueLoadingButton from 'vue-loading-button'
import Select2 from 'vue3-select2-component';
import moment from 'moment';
import router from "@/router";
export default {
  name: 'AddPayroll',
  components: { Select2 },
  Vcomponents: {
    VueLoadingButton
  },

  mounted() {
    this.loadTeachersList();
  },

  data() {
    return {
      isDisabled: false,
      TeacherOptions: [
        { id: '', text: 'seçme', customValue: '',teacherMetaDataId:null }
      ],
      options: ['Pending', 'Paid'],
      appointmentDetail:[],
      selectedTeacher: '',
      status: 'Paid',
      startDate: '',
      endDate: '',
      totalHours: 0,
      totalAmount: 0,
      totalMinuts: 0,
      hourlyRate: 0,
      tableDatas: [],
      toasts: [],
      fields: [
        {
          key: "select",
          label: "",
          _style: "min-width:1%",
          sorter: false,
          filter: false,
        },
        { key: "appointmentId", label: "ID", _style: "min-width:40%" },
        { key: "appointmentType", label: "Appointment Type", _style: "min-width:40%" },
        { key: "studentName", label: "Student Name", _style: "min-width:20%;" },
        { key: "startDateTime", label: "Start Date Time", _style: "min-width:20%;" },
        { key: "endDateTime", label: "End Date Time", _style: "min-width:20%;" },
        { key: "mints", label: "Total Minutes ", _style: "min-width:20%;" },
      ]
    };
  },

  computed: {
    tableData() {
      return this.tableDatas.map((user) => {
        return {
          ...user,
          _classes: [user._classes, user._selected ? "table-selected" : ""]
        };
      });
    },
  },
  methods: {
    rowClicked(item, index, column, e) {
      if (!["INPUT", "LABEL"].includes(e.target.tagName)) {
        this.check(item, index);
      }
    },
    check(item, index) {
      const val = Boolean(this.tableDatas[index]._selected);
      console.log(item, index);
      this.$set(this.tableDatas[index], "_selected", !val);
      this.calculation(index)
    },
    checkAll(checked) {
      console.log(checked);
      this.tableDatas.forEach((item, index) => {
        console.log(item, index);
        this.$set(this.tableDatas[index], "_selected", checked);
        this.calculation(index)
      });
    },

    calculation(index) {
      if (this.tableDatas[index]._selected === true) {
        this.totalMinuts = this.totalMinuts + this.tableDatas[index].mints;
        this.totalHours = this.timeConvert(this.totalMinuts);
        this.totalAmount = (this.hourlyRate * this.totalHours);
        let obj = {
          AppointmentId: this.tableDatas[index].appointmentId,
          StudentId : this.tableDatas[index].studentId ,
          Mints : this.tableDatas[index].mints,
          Paid :true
        }
        this.appointmentDetail.push(obj);
      } else {
        this.totalMinuts = this.totalMinuts - this.tableDatas[index].mints;
        this.totalHours = this.timeConvert(this.totalMinuts);
        this.totalAmount = (this.hourlyRate * this.totalHours);
        this.appointmentDetail.splice(index);
      }
    },
    dateValidator() {
      if (this.startDate && this.endDate) {
        if (!(moment(this.startDate, 'YYYY-MM-DD').isSameOrBefore(moment(this.endDate, 'YYYY-MM-DD')))) {
          this.endDate = ''
          this.$toast.error('Bitiş tarihi, başlangıç ​​tarihinden büyük olmalıdır.')
        }
      }
    },
    showToast: function (text = 'İşlem Başarıyla Onaylandı', header = 'Bilgi') {
      this.toasts.push({ text, header })
    },
    async loadTeachersList() {
      this.isListLoading = true;
      const result = await TeacherAdministration.teachersDropdown()
      console.log("teacher List", result);
      if (result.data) {
        result.data.forEach(d => {
          this.TeacherOptions.push({
            text: d.label,
            id: d.value,
            customValue: d.customValue,
            teacherMetaDataId : d.teacherMetaDataId,
          })
        });
      }
    },

    myChangeEvent(val) {
      console.log(val);
    },
    mySelectEvent({ id, text, customValue,teacherMetaDataId }) {
      console.log({ id, text, customValue,teacherMetaDataId });
      this.selectedTeacher = id;
      this.hourlyRate = customValue;
      this.teacherMetaDataId = teacherMetaDataId;
    },
    async filterRecord() {
      this.appointmentDetail = []; 
      this.loadTeachersAppointment(this.selectedTeacher);
    },
    async loadTeachersAppointment(selectedTeacher) {
      const result = await AppointmentAdministration.listAppointmentsTeacherForPay(selectedTeacher, this.startDate, this.endDate)
      console.log("selectedTeacher Appo", result);
      this.tableDatas = result.data;
    },
    async submitPayroll() {
      
      if(this.appointmentDetail.length == 0){        
      this.$toast.warning('Lütfen en az 1 randevu seçin');
        return;
      }else if(this.totalAmount == 0){        
      this.$toast.warning('Lütfen saatlik ücret ekleyin');
      return;
      }
      this.isDisabled = true;
      let stringyfyData = JSON.stringify(this.appointmentDetail);
      const result = await TeacherAdministration.teacherPay(this.teacherMetaDataId, this.totalMinuts, this.totalHours,this.hourlyRate,this.totalAmount,this.status,stringyfyData)
      console.log("teacherPay ", result);   
      this.$toast.success('Bordro başarıyla eklendi!');
      router.push({ path: '/Payroll' })
    },
    timeConvert(n) {
      var num = n;
      var hours = (num / 60);
      return hours;
    }
  },
}
</script>